import React from 'react'
import { useHistory } from 'react-router'
import Button from 'components/Button'
import List from './list'
import FilterBox from './filter'
import EditDialog from '../edit/EditDialog'
import { useState } from 'react'
import ConfirmDialog from 'components/Dialogs/ConfirmDialog'
import useTip from 'hooks/useTip'

function Tip() {
  const router = useHistory()
  const [editData, setEditData] = React.useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [showDelete, setShowDelete] = React.useState(false)
  const headers = ['No', 'Name', 'Created At', '', '']

  const { state, fetchTips, deleteTips } = useTip()

  React.useEffect(() => {
    fetchTips()
  }, [])

  return (
    <>
      <List
        headers={headers}
        data={state.tips}
        title="Tips"
        onDelete={(data) => {
          setEditData(data)
          setShowDelete(true)
        }}
        loading={state.loading}
        onEdit={(data) => {
          setEditData(data)
          setModalOpen(true)
        }}
        filter={<FilterBox />}
        onSelect={(e) => {}}
        buttons={
          <>
            <Button
              onClick={() => {
                setModalOpen(true)
              }}
            >
              Add New
            </Button>
            <Button
              onClick={() => {
                fetchTips()
              }}
            >
              Refresh
            </Button>
          </>
        }
      />
      <EditDialog
        modalOpen={modalOpen}
        data={editData}
        onToggle={() => {
          setModalOpen(false)
          fetchTips()
        }}
      />
      <ConfirmDialog
        modalOpen={showDelete}
        title={`Delete Admin`}
        body={`Are you sure to delete ${editData?.name}?`}
        onToggle={() => setShowDelete(false)}
        onConfirm={() => {
          setShowDelete(false)
          deleteTips(editData.id)
          fetchTips()
        }}
      />
    </>
  )
}

export default Tip
