import React, { useState } from 'react'
import EntryGrid from 'components/Inputs/EntryGrid'
import { Col, FormGroup, Row, Form } from 'reactstrap'
import Button from 'components/Button'
import { useForm } from 'react-hook-form'

import useLiveTip from 'hooks/useLiveTip'
import { useParams } from 'react-router'
import TextBox from 'components/Inputs/TextBox'

function InputForm({ title, data }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm()

  const { id } = useParams()

  React.useEffect(() => {
    if (data) {
      setValue('live_tip_match_id', data.live_tip_match_id)
      setValue('body', data.body)
    } else {
      setValue('live_tip_match_id', id)
    }
  }, [])

  const { updateLiveTips, storeLiveTips } = useLiveTip()

  const submitData = async (values) => {
    data ? await updateLiveTips(data.id, values) : await storeLiveTips(values)
  }

  return (
    <EntryGrid title={title}>
      <Form onSubmit={handleSubmit(submitData)}>
        <div className="pt-2 pb-2">
          <Row>
            <Col md="12">
              <FormGroup>
                <label>Body</label>
                <TextBox
                  className="form-control"
                  placeholder="Name"
                  type="textarea"
                  registerProps={register('body', {
                    required: 'Body is required',
                  })}
                  errors={errors.body}
                />
              </FormGroup>
            </Col>
          </Row>
          <div className="mt-5 text-center">
            <Button>Save</Button>
            <Button
              className="ml-2"
              onClick={() => {
                reset()
              }}
            >
              Reset
            </Button>
          </div>
        </div>
      </Form>
    </EntryGrid>
  )
}

export default InputForm
