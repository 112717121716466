import React from 'react'
import { getAll, store, update, deleteData } from 'services/matchTipService'


const initialState = { matchTips: [], loading: false }

function reducer(state, action) {
  switch (action.type) {
    case 'SET_MATCH_TIP':
      return { ...state, matchTips: action.payload.data }
    case 'SET_LOADING':
      return { ...state, loading: action.payload }
    default:
      throw new Error()
  }
}

const useMatchTip = () => {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  const fetchMatchTips = async (query) => {
    dispatch({ type: 'SET_LOADING', payload: true })
    let data = await getAll(query)
    dispatch({ type: 'SET_MATCH_TIP', payload: { data: data } })
    dispatch({ type: 'SET_LOADING', payload: false })
  }

  const storeMatchTips = async (values) => {
    await store(values)
  }
  const updateMatchTips = async (id, values) => {
    await update(id, values)
  }
  const deleteMatchTips = async (id) => {
    await deleteData(id)
  }

  return {
    fetchMatchTips,
    storeMatchTips,
    updateMatchTips,
    deleteMatchTips,
    state: state,
  }
}

export default useMatchTip
