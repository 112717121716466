import React from 'react'
import Button from 'components/Button'
import List from './list'
import queryString from 'query-string'
import useAdmin from 'hooks/useAdmin'
import { useHistory } from 'react-router'
import { useState } from 'react'
import EditDialog from '../edit/EditDialog'

function AdminActive() {
  const router = useHistory()
  const headers = ['No', 'Name', 'Created At']
  const [modalOpen, setModalOpen] = useState(false)
  const { state, fetchAdmins, fetchActiveAdmin } = useAdmin()

  const getAdmins = async () => {
    const query = queryString.parse(router.location.search)
    query.search = 'normalAdmins'
    await fetchAdmins(query)
  }
  React.useEffect(() => {
    getAdmins()
    fetchActiveAdmin()
  }, [])

  return (
    <>
      <List
        headers={headers}
        data={state.admins}
        activeAdmin={state.activeAdmin.name}
        title="Edit Admin Setting"
        loading={state.loading}
        onSelect={(e) => {}}
        buttons={
          <>
            <Button
              onClick={() => {
                setModalOpen(true)
              }}
            >
              Define
            </Button>
          </>
        }
      />
      <EditDialog
        modalOpen={modalOpen}
        onToggle={() => {
          setModalOpen(false)
          getAdmins()
          fetchActiveAdmin()
        }}
      />
    </>
  )
}

export default AdminActive
