import React from 'react'
import { get, update } from 'services/settingService'


const initialState = { setting: {}, loading: false }

function reducer(state, action) {
  switch (action.type) {
    case 'SET_SETTING':
      return { ...state, setting: action.payload.data }
    case 'SET_LOADING':
      return { ...state, loading: action.payload }
    default:
      throw new Error()
  }
}

const useSetting = () => {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  const fetchSettings = async () => {
    dispatch({ type: 'SET_LOADING', payload: true })
    let data = await get()
    dispatch({ type: 'SET_SETTING', payload: { data: data } })
    dispatch({ type: 'SET_LOADING', payload: false })
  }

  const updateSettings = async ( values) => {
    await update(values)
  }

  return {
    fetchSettings,
    updateSettings,
    state: state,
  }
}

export default useSetting
