import React from 'react'
import { useHistory } from 'react-router'
import Button from 'components/Button'
import List from './list'
import FilterBox from './filter'
import EditDialog from '../edit/EditDialog'
import { useState } from 'react'
import ConfirmDialog from 'components/Dialogs/ConfirmDialog'
import useLiveTipMatch from 'hooks/useLiveTipMatch'

function LiveTipMatch() {
  const router = useHistory()
  const [editData, setEditData] = React.useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [showDelete, setShowDelete] = React.useState(false)
  const [filterDate, setFilterDate] = React.useState(null)

  const headers = [
    'No',
    'competition Name',
    'hometeam Name',
    'awayteam Name',
    'Created At',
    '',
    '',
    '',
  ]

  const { state, fetchLiveTipMatchs, deleteLiveTipMatchs } = useLiveTipMatch()
  const getLiveTipMatchs = async () => {
    await fetchLiveTipMatchs()
  }
  React.useEffect(() => {
    getLiveTipMatchs()
  }, [])


  return (
    <>
      <List
        headers={headers}
        data={state?.liveTipMatchs}
        title="Live Tip Match"
        onDelete={(data) => {
          setEditData(data)
          setShowDelete(true)
        }}
        loading={state.loading}
        onEdit={(data) => {
          setEditData(data)
          setModalOpen(true)
        }}
        filter={
          <FilterBox/>
        }
        onSelect={(e) => {}}
        buttons={
          <>
            <Button
              onClick={() => {
                setModalOpen(true)
              }}
            >
              Add New
            </Button>
            <Button
              onClick={() => {
                getLiveTipMatchs()
              }}
            >
              Refresh
            </Button>
          </>
        }
      />
      <EditDialog
        modalOpen={modalOpen}
        data={editData}
        onToggle={() => {
          setModalOpen(false)
          getLiveTipMatchs()
        }}
      />
      <ConfirmDialog
        modalOpen={showDelete}
        title={`Delete Live Tip Match`}
        body={`Are you sure to delete ${editData?.competition?.name}?`}
        onToggle={() => setShowDelete(false)}
        onConfirm={async () => {
          setShowDelete(false)
          await deleteLiveTipMatchs(editData?.id)
          await getLiveTipMatchs()
        }}
      />
    </>
  )
}

export default LiveTipMatch
