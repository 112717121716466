import React from 'react'
import { getAll, store, update, deleteData } from 'services/tipService'


const initialState = { tips: [], loading: false }

function reducer(state, action) {
  switch (action.type) {
    case 'SET_TIP':
      return { ...state, tips: action.payload.data }
    case 'SET_LOADING':
      return { ...state, loading: action.payload }
    default:
      throw new Error()
  }
}

const useTip = () => {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  const fetchTips = async (id) => {
    dispatch({ type: 'SET_LOADING', payload: true })
    let data = await getAll(id)
    dispatch({ type: 'SET_TIP', payload: { data: data } })
    dispatch({ type: 'SET_LOADING', payload: false })
  }

  const storeTips = async (values) => {
    await store(values)
  }
  const updateTips = async (id, values) => {
    await update(id, values)
  }
  const deleteTips = async (id) => {
    await deleteData(id)
  }

  return {
    fetchTips,
    storeTips,
    updateTips,
    deleteTips,
    state: state,
  }
}

export default useTip
