import React from 'react'
import { useParams } from 'react-router'
import Button from 'components/Button'
import List from './list'
import FilterBox from './filter'
import EditDialog from '../edit/EditDialog'
import { useState } from 'react'
import ConfirmDialog from 'components/Dialogs/ConfirmDialog'
import useLiveTip from 'hooks/useLiveTip'

function LiveTip() {
  const { id } = useParams()
  const [editData, setEditData] = React.useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [showDelete, setShowDelete] = React.useState(false)

  const headers = ['No', 'body', '','Created At', '', '']

  const { state, fetchLiveTips, deleteLiveTips,updateLiveTips } = useLiveTip()
  const getLiveTips = async (id) => {
    await fetchLiveTips(id)
  }
  React.useEffect(() => {
    getLiveTips(id)
  }, [])

  const changeStatus = async (live_tip_id, status) => {
    await updateLiveTips(live_tip_id, {
      status: status
    })
    await getLiveTips(id)
  }

  return (
    <>
      <List
        headers={headers}
        data={state?.liveTips}
        title="Live  Tips"
        onDelete={(data) => {
          setEditData(data)
          setShowDelete(true)
        }}
        loading={state.loading}
        onEdit={(data) => {
          setEditData(data)
          setModalOpen(true)
        }}
        changeStatus={changeStatus}
        filter={<FilterBox />}
        onSelect={(e) => {}}
        buttons={
          <>
            <Button
              onClick={() => {
                setEditData(null)
                setModalOpen(true)
              }}
            >
              Add New
            </Button>
            <Button
              onClick={() => {
                getLiveTips(id)
              }}
            >
              Refresh
            </Button>
          </>
        }
      />
      <EditDialog
        modalOpen={modalOpen}
        data={editData}
        onToggle={() => {
          setModalOpen(false)
          getLiveTips(id)
        }}
      />
      <ConfirmDialog
        modalOpen={showDelete}
        title={`Delete Live Tip`}
        body={`Are you sure to delete ${editData?.live_tip_match?.competition?.name}?`}
        onToggle={() => setShowDelete(false)}
        onConfirm={async () => {
          setShowDelete(false)
          await deleteLiveTips(editData?.id)
          await getLiveTips(id)
        }}
      />
    </>
  )
}

export default LiveTip
