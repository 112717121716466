import React from 'react'
import { useHistory } from 'react-router'
import Button from 'components/Button'
import List from './list'
import FilterBox from './filter'
import EditDialog from '../edit/EditDialog'
import { useState } from 'react'
import ConfirmDialog from 'components/Dialogs/ConfirmDialog'
import usePointFilling from 'hooks/usePointFilling'
import usePoint from 'hooks/usePoint'

import queryString from 'query-string'
import useMember from 'hooks/useMember'

function PointFilling() {
  const router = useHistory()
  const [editData, setEditData] = React.useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [showDelete, setShowDelete] = React.useState(false)
  const headers = ['No', 'Point', 'Status','Date', 'Admin']
  
  const [status, setStatus] = useState(null)
  const { state, fetchPointFilling } = usePointFilling()
  const { state: pointState, fetchPoint } = usePoint()
  const { state: memberState, fetchEachMember } = useMember()

  const data = queryString.parse(router.location.search)

  React.useEffect(() => {
    fetchPointFilling(queryString.parse(router.location.search))
    fetchPoint()
    fetchEachMember(data.member_id)
  }, [])


  return (
    <>
      <List
        headers={headers}
        data={state.points}
        title="Point Filling History"
        onDelete={(data) => {
          setEditData(data)
          setShowDelete(true)
        }}
        loading={state.loading}
        onEdit={(data) => {
          setEditData(data)
          setModalOpen(true)
        }}
        filter={
          <>
            {pointState.points.length > 0 && (
              <div>
                <h3>
                  Current MMK for 1 Point -{' '}
                  {JSON.stringify(pointState.points[0]?.value)}
                </h3>
              </div>
            )}
          </>
        }
        // onSelect={(e) => {}}
        buttons={
          <div className='d-flex justify-content-between'>
            <div>
            <Button
              onClick={() => {
                setStatus('add')
                setModalOpen(true)
              }}
            >
              Add Point
            </Button>
            <Button
              onClick={() => {
                setStatus('remove')
                setModalOpen(true)
              }}
            >
              Remove Point
            </Button>
            <Button
              onClick={() => {
                fetchPointFilling(queryString.parse(router.location.search))
              }}
            >
              Refresh
            </Button>
            </div>
            <div>
              <h3> Current Point - {memberState.member?.point}</h3>
            </div>
          </div>
        }
      />
      <EditDialog
        status={status}
        modalOpen={modalOpen}
        data={editData}
        memberPoint = {memberState.member?.point}
        currentPointValue={pointState.points[0]?.value}
        onToggle={() => {
          setStatus(null)
          setModalOpen(false)
          fetchPointFilling(queryString.parse(router.location.search))
          fetchEachMember(data.member_id)
        }}
      />
      {/* <ConfirmDialog
        modalOpen={showDelete}
        title={`Delete Believe`}
        body={`Are you sure to delete ${editData?.name}?`}
        onToggle={() => setShowDelete(false)}
        onConfirm={() => {
          setShowDelete(false)
          deletePointFilling(editData.id)
          fetchPointFilling()
        }}
      /> */}
    </>
  )
}

export default PointFilling
