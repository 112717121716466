import React from 'react'
import Button from 'components/Button'
import Table from 'components/Table'
import CheckBox from 'components/Inputs/CheckBox'
import dayjs from 'dayjs'

function List({
  headers,
  data,
  onEdit,
  title,
  buttons,
  onDelete,
  selectedData = [],
  onSelect,
  filter,
  loading,
}) {
  return (
    <>
      <Table
        title={title}
        headers={headers.map((e) => (
          <th scope="col" key={`thead-${Math.random()}`}>
            {e}
          </th>
        ))}
        loading={loading}
        filter={
          <>
            {data.length > 0 && (
              <div>
                <h3>Current 1 Point Value = {data[0].value} MMK</h3>
              </div>
            )}
          </>
        }
        buttons={buttons}
        rows={data?.map((e, index) => (
          <tr key={`table-row-${Math.random()}`}>
            <td>{index + 1}</td>
            <td>{e.value}</td>
            <td>{dayjs(e.created_at).format('DD/MM/YYYY')}</td>
          </tr>
        ))}
      />
    </>
  )
}

export default List
