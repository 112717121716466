import React from 'react'
import { getAll, store, update, deleteData } from 'services/infoService'


const initialState = { infos: [], loading: false }

function reducer(state, action) {
  switch (action.type) {
    case 'SET_INFO':
      return { ...state, infos: action.payload.data }
    case 'SET_LOADING':
      return { ...state, loading: action.payload }
    default:
      throw new Error()
  }
}

const useInfo = () => {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  const fetchInfos = async () => {
    dispatch({ type: 'SET_LOADING', payload: true })
    let data = await getAll()
    dispatch({ type: 'SET_INFO', payload: { data: data } })
    dispatch({ type: 'SET_LOADING', payload: false })
  }

  const storeInfos = async (values) => {
    await store(values)
  }
  const updateInfos = async (id, values) => {
    await update(id, values)
  }
  const deleteInfos = async (id) => {
    await deleteData(id)
  }

  return {
    fetchInfos,
    storeInfos,
    updateInfos,
    deleteInfos,
    state: state,
  }
}

export default useInfo
