import { sendData, getData } from './apiService'
import { NotificationManager } from 'react-notifications'

export async function getAll(query) {
  try {
    let response = await getData(`member/?&${new URLSearchParams(query)}`)
    return response.data
  } catch (e) {
    NotificationManager.error('Opps. Something wrong')
    return false
  }
}
export async function getEach(id) {
  try {
    let response = await getData(`member/${id}`)
    return response.data
  } catch (e) {
    NotificationManager.error('Opps. Something wrong')
    return false
  }
}
export async function store(data) {
  try {
    let response = await sendData('member', data)
    return response.data
  } catch (e) {
    NotificationManager.error(JSON.stringify(e?.response?.data?.errors))
    return false
  }
}
export async function update(id, data) {
  try {
    let response = await sendData(`member/${id}`, data, 'PUT')
    return response.data
  } catch (e) {
    NotificationManager.error('Opps. Something wrong')
    return false
  }
}

export async function deleteData(_id) {
  try {
    await sendData(`member/${_id}`, null, 'DELETE')
    return true
  } catch (e) {
    NotificationManager.error('Opps. Something wrong')
    return false
  }
}
