import React from 'react'
import { getAll, store, update, deleteData } from 'services/freeRecordService'

const initialState = { freeRecords: [], loading: false }

function reducer(state, action) {
  switch (action.type) {
    case 'SET_FREE_RECORD':
      return { ...state, freeRecords: action.payload.data }
    case 'SET_LOADING':
      return { ...state, loading: action.payload }
    default:
      throw new Error()
  }
}

const useFreeRecord = () => {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  const fetchFreeRecords = async () => {
    dispatch({ type: 'SET_LOADING', payload: true })
    let data = await getAll()
    dispatch({ type: 'SET_FREE_RECORD', payload: { data: data } })
    dispatch({ type: 'SET_LOADING', payload: false })
  }

  const storeFreeRecords = async (values) => {
    await store(values)
  }
  const updateFreeRecords = async (id, values) => {
    await update(id, values)
  }
  const deleteFreeRecords = async (id) => {
    await deleteData(id)
  }

  return {
    fetchFreeRecords,
    storeFreeRecords,
    updateFreeRecords,
    deleteFreeRecords,
    state: state,
  }
}

export default useFreeRecord
