import React, { useState } from 'react'
import Button from 'components/Button'
import List from './list'
import FilterBox from './filter'
import useAdmin from 'hooks/useAdmin'
import { useSelector } from 'react-redux'
import EditDialog from '../edit/EditDialog'
import usePoint from 'hooks/usePoint'

function AdminPointTransfers() {
  const headers = ['No', 'Name', 'Email', 'Role', 'Point', 'Created At', '']

  const [data, setData] = useState(null)
  const [showPointTransfer, setShowPointTransfer] = useState(false)

  const { state, fetchPointTransferAdmins } = useAdmin()
  const admin = useSelector((state) => state.admin.adminInfo)
  const { state: pointState, fetchPoint } = usePoint()
  const { state: adminState, getAdmin } = useAdmin()

  React.useEffect(() => {
    fetchPointTransferAdmins()
    fetchPoint()
    getAdmin(admin?.id)
  }, [])

  return (
    <>
      <List
        headers={headers}
        data={state.pointTransfers}
        title="Admin Point Transfers"
        loading={state.loading}
        filter={<FilterBox />}
        onSelect={(e) => {}}
        transferPoint={(data) => {
          setData(data)
          setShowPointTransfer(true)
        }}
        points={adminState.admin?.point}
        buttons={
          <>
            <div className="d-flex justify-content-between">
              <div>
                <Button
                  onClick={() => {
                    fetchPointTransferAdmins()
                  }}
                >
                  Refresh
                </Button>
              </div>
              <div>
                <h3> Current Point - {adminState.admin?.point}</h3>
              </div>
            </div>
          </>
        }
      />
      <EditDialog
        modalOpen={showPointTransfer}
        data={data}
        onToggle={() => {
          setShowPointTransfer(false)
          fetchPointTransferAdmins()
          getAdmin(admin?.id)
        }}
        currentPointValue={pointState.points[0]?.value}
        userPoints={adminState.admin?.point}
      />
    </>
  )
}

export default AdminPointTransfers
