import React from 'react'
import { getAll, store, update, deleteData } from 'services/onlineTip'


const initialState = { onlineTips: [], loading: false }

function reducer(state, action) {
  switch (action.type) {
    case 'SET_ONLINE_TIP':
      return { ...state, onlineTips: action.payload.data }
    case 'SET_LOADING':
      return { ...state, loading: action.payload }
    default:
      throw new Error()
  }
}

const useOnlineTip = () => {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  const fetchOnlineTips = async (id) => {
    dispatch({ type: 'SET_LOADING', payload: true })
    let data = await getAll(id)
    console.log(data);
    dispatch({ type: 'SET_ONLINE_TIP', payload: { data: data } })
    dispatch({ type: 'SET_LOADING', payload: false })
  }

  const storeOnlineTips = async (values) => {
    await store(values)
  }
  const updateOnlineTips = async (id, values) => {
    await update(id, values)
  }
  const deleteOnlineTips = async (id) => {
    await deleteData(id)
  }

  return {
    fetchOnlineTips,
    storeOnlineTips,
    updateOnlineTips,
    deleteOnlineTips,
    state: state,
  }
}

export default useOnlineTip
