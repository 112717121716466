import React from 'react'
import { getAll, store, update, deleteData } from 'services/pointService'


const initialState = { points: [], loading: false }

function reducer(state, action) {
  switch (action.type) {
    case 'SET_POINT':
      return { ...state, points: action.payload.data }
    case 'SET_LOADING':
      return { ...state, loading: action.payload }
    default:
      throw new Error()
  }
}

const useBelieve = () => {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  const fetchPoint = async () => {
    dispatch({ type: 'SET_LOADING', payload: true })
    let data = await getAll()
    dispatch({ type: 'SET_POINT', payload: { data: data } })
    dispatch({ type: 'SET_LOADING', payload: false })
  }
  

  const storePoint = async (values) => {
    await store(values)
  }
  const updatePoint = async (id, values) => {
    await update(id, values)
  }
  const deletePoint = async (id) => {
    await deleteData(id)
  }

  return {
    fetchPoint,
    storePoint,
    updatePoint,
    deletePoint,
    state: state,
  }
}

export default useBelieve
