import { sendData, getData } from './apiService'
import { NotificationManager } from 'react-notifications'

export async function getAll(query) {
  try {
    let response = await getData(`points-admin/?&${new URLSearchParams(query)}`)
    return response.data
  } catch (e) {
    NotificationManager.error('Opps. Something wrong')
    return false
  }
}
export async function store(data) {
  try {
    let response = await sendData('points-admin/add', data, 'PUT')
    return response.data
  } catch (e) {
    NotificationManager.error('Opps. Something wrong')
    return false
  }
}
export async function remove(data) {
  try {
    let response = await sendData('points-admin/remove', data, 'PUT')
    return response.data
  } catch (e) {
    NotificationManager.error('Opps. Something wrong')
    return false
  }
}
