import React from 'react'
import { getAll, store, update, deleteData } from 'services/competitionService'

const initialState = { competitions: [], loading: false }

function reducer(state, action) {
  switch (action.type) {
    case 'SET_COMPETITION':
      return { ...state, competitions: action.payload.data }
    case 'SET_LOADING':
      return { ...state, loading: action.payload }
    default:
      throw new Error()
  }
}

const useCompetition = () => {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  const fetchCompetitions = async () => {
    dispatch({ type: 'SET_LOADING', payload: true })
    let data = await getAll()
    dispatch({ type: 'SET_COMPETITION', payload: { data: data } })
    dispatch({ type: 'SET_LOADING', payload: false })
  }

  const storeCompetitions = async (values) => {
    await store(values)
  }
  const updateCompetitions = async (id, values) => {
    await update(id, values)
  }
  const deleteCompetitions = async (id) => {
    await deleteData(id)
  }

  return {
    fetchCompetitions,
    storeCompetitions,
    updateCompetitions,
    deleteCompetitions,
    state: state,
  }
}

export default useCompetition
