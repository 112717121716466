import React from 'react'
import { useHistory } from 'react-router'
import Button from 'components/Button'
import List from './list'
import FilterBox from './filter'
import EditDialog from '../edit/EditDialog'
import { useState } from 'react'
import ConfirmDialog from 'components/Dialogs/ConfirmDialog'
import usePointFilling from 'hooks/usePointFilling'
import usePoint from 'hooks/usePoint'

import queryString from 'query-string'
import useMember from 'hooks/useMember'
import useAdminPoint from 'hooks/useAdminPoint'
import useAdmin from 'hooks/useAdmin'

function AdminPoint() {
  const router = useHistory()
  const [editData, setEditData] = React.useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [showDelete, setShowDelete] = React.useState(false)
  const headers = ['No', 'Point', 'Status', 'Date']

  const [status, setStatus] = useState(null)
  const { state, fetchAdminPoint } = useAdminPoint()
  const { state: pointState, fetchPoint } = usePoint()
  const { state: adminState, getAdmin } = useAdmin()

  const data = queryString.parse(router.location.search)

  React.useEffect(() => {
    fetchAdminPoint(queryString.parse(router.location.search))
    fetchPoint()
    getAdmin(data.admin_id)
  }, [])

  return (
    <>
      <List
        headers={headers}
        data={state.points}
        title="Admin Point Filling History"
        onDelete={(data) => {
          setEditData(data)
          setShowDelete(true)
        }}
        loading={state.loading}
        onEdit={(data) => {
          setEditData(data)
          setModalOpen(true)
        }}
        filter={
          <>
            {pointState.points.length > 0 && (
              <div>
                <h3>
                  Current MMK for 1 Point -{' '}
                  {JSON.stringify(pointState.points[0]?.value)}
                </h3>
              </div>
            )}
          </>
        }
        // onSelect={(e) => {}}
        buttons={
          <div className="d-flex justify-content-between">
            <div>
              <Button
                onClick={() => {
                  setStatus('add')
                  setModalOpen(true)
                }}
              >
                Add Point
              </Button>
              <Button
                onClick={() => {
                  setStatus('remove')
                  setModalOpen(true)
                }}
              >
                Remove Point
              </Button>
              <Button
                onClick={() => {
                  fetchAdminPoint(queryString.parse(router.location.search))
                }}
              >
                Refresh
              </Button>
            </div>
            <div>
              <h3> Current Point - {adminState.admin?.point}</h3>
            </div>
          </div>
        }
      />
      <EditDialog
        status={status}
        modalOpen={modalOpen}
        data={editData}
        memberPoint={adminState.admin?.point}
        currentPointValue={pointState.points[0]?.value}
        onToggle={() => {
          setStatus(null)
          setModalOpen(false)
          fetchAdminPoint(queryString.parse(router.location.search))
        }}
      />
      {/* <ConfirmDialog
        modalOpen={showDelete}
        title={`Delete Believe`}
        body={`Are you sure to delete ${editData?.name}?`}
        onToggle={() => setShowDelete(false)}
        onConfirm={() => {
          setShowDelete(false)
          deletePointFilling(editData.id)
          fetchPointFilling()
        }}
      /> */}
    </>
  )
}

export default AdminPoint
