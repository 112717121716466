import { sendData, getData } from './apiService'
import { NotificationManager } from 'react-notifications'


export async function getAll(online_match_tip_id) {
  try {
    let response = await getData(`online-tip?online_tip_match_id=${online_match_tip_id}`)
    return response.data
  } catch (e) {
    NotificationManager.error('Opps. Something wrong')
    return false
  }
}
export async function store(data) {
  try {
    let response = await sendData('online-tip', data)
    return response.data
  } catch (e) {
    NotificationManager.error('Opps. Something wrong')
    return false
  }
}
export async function update(id,data) {
  try {
    let response = await sendData(`online-tip/${id}`, data, 'PUT')
    return response.data
  } catch (e) {
    NotificationManager.error('Opps. Something wrong')
    return false
  }
}

export async function deleteData(_id) {
  try {
    await sendData(`online-tip/${_id}`, null, 'DELETE')
    return true
  } catch (e) {
    NotificationManager.error('Opps. Something wrong')
    return false
  }
}

