import React from 'react'
import { useParams } from 'react-router'
import Button from 'components/Button'
import List from './list'
import FilterBox from './filter'
import EditDialog from '../edit/EditDialog'
import { useState } from 'react'
import ConfirmDialog from 'components/Dialogs/ConfirmDialog'
import useOnlineTip from 'hooks/useOnlineTip'

function OnlineTip() {
  const { id } = useParams()
  const [editData, setEditData] = React.useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [showDelete, setShowDelete] = React.useState(false)

  const headers = ['No', 'body', '','Created At', '', '']

  const { state, fetchOnlineTips, deleteOnlineTips,updateOnlineTips } = useOnlineTip()
  const getOnlineTips = async (id) => {
    await fetchOnlineTips(id)
  }
  React.useEffect(() => {
    getOnlineTips(id)
  }, [])

  const changeStatus = async (Online_tip_id, status) => {
    await updateOnlineTips(Online_tip_id, {
      status: status
    })
    await getOnlineTips(id)
  }

  return (
    <>
      <List
        headers={headers}
        data={state?.onlineTips}
        title="Online  Tips"
        onDelete={(data) => {
          setEditData(data)
          setShowDelete(true)
        }}
        loading={state.loading}
        onEdit={(data) => {
          setEditData(data)
          setModalOpen(true)
        }}
        changeStatus={changeStatus}
        filter={<FilterBox />}
        onSelect={(e) => {}}
        buttons={
          <>
            <Button
              onClick={() => {
                setEditData(null)
                setModalOpen(true)
              }}
            >
              Add New
            </Button>
            <Button
              onClick={() => {
                getOnlineTips(id)
              }}
            >
              Refresh
            </Button>
          </>
        }
      />
      <EditDialog
        modalOpen={modalOpen}
        data={editData}
        onToggle={() => {
          setModalOpen(false)
          getOnlineTips(id)
        }}
      />
      <ConfirmDialog
        modalOpen={showDelete}
        title={`Delete Online Tip`}
        body={`Are you sure to delete ${editData?.Online_tip_match?.competition?.name}?`}
        onToggle={() => setShowDelete(false)}
        onConfirm={async () => {
          setShowDelete(false)
          await deleteOnlineTips(editData?.id)
          await getOnlineTips(id)
        }}
      />
    </>
  )
}

export default OnlineTip
