import React from 'react'
import EntryGrid from 'components/Inputs/EntryGrid'
import { Col, FormGroup, Row, Form } from 'reactstrap'
import Button from 'components/Button'
import TextBox from 'components/Inputs/TextBox'
import { useForm } from 'react-hook-form'
import { useEffect } from 'react'
import usePoint from 'hooks/usePoint'
import SelectBox from 'components/SelectBox'
import queryString from 'query-string'
import useAdmin from 'hooks/useAdmin'
import { useHistory } from 'react-router'

function InputForm({ title }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm()

  const router = useHistory()
  const { state, fetchAdmins, updateAdmins } = useAdmin()

  React.useEffect(() => {
    const query = queryString.parse(router.location.search)
    query.search = 'normalAdmins'
    fetchAdmins(query)
  }, [])

  const adminOptions = state?.admins?.map((e) => {
    return {
      label: e.name,
      value: e.id,
    }
  })
  const submitData = async (values) => {
    reset()
    await updateAdmins(values.admin_id, {active: 1})
  }

  return (
    <EntryGrid title={title}>
      <Form onSubmit={handleSubmit(submitData)}>
        <div className="pt-2 pb-2">
          <Row>
            <Col md="6">
              <FormGroup>
                <label>Active Admin</label>
                <SelectBox
                  control={control}
                  name="admin_id"
                  label="Admin"
                  options={adminOptions}
                  rules={{ required: true }}
                />
              </FormGroup>
            </Col>
          </Row>
          <div className="mt-5 text-center">
            <Button>Save</Button>
            <Button
              className="ml-2"
              onClick={() => {
                reset()
              }}
            >
              Reset
            </Button>
          </div>
        </div>
      </Form>
    </EntryGrid>
  )
}

export default InputForm
