import React from 'react'
import { getAll, store, update, deleteData , remove} from 'services/pointFillingService'


const initialState = { points: [], loading: false }

function reducer(state, action) {
  switch (action.type) {
    case 'SET_POINT_FILLING':
      return { ...state, points: action.payload.data }
    case 'SET_LOADING':
      return { ...state, loading: action.payload }
    default:
      throw new Error()
  }
}

const usePointFilling = () => {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  const fetchPointFilling = async (query) => {
    dispatch({ type: 'SET_LOADING', payload: true })
    let data = await getAll(query)
    dispatch({ type: 'SET_POINT_FILLING', payload: { data: data } })
    dispatch({ type: 'SET_LOADING', payload: false })
  }

  const storePointFilling = async (values) => {
    await store(values)
  }
  const removePointFilling = async (values) => {
    await remove(values)
  }
  // const updatePointFilling = async (id, values) => {
  //   await update(id, values)
  // }
  // const deletePointFilling = async (id) => {
  //   await deleteData(id)
  // }

  return {
    fetchPointFilling,
    storePointFilling,
    removePointFilling,
    // updatePointFilling,
    // deletePointFilling,
    state: state,
  }
}

export default usePointFilling
