import React, { useState } from 'react'
import EntryGrid from 'components/Inputs/EntryGrid'
import { Col, FormGroup, Row, Form } from 'reactstrap'
import Button from 'components/Button'
import TextBox from 'components/Inputs/TextBox'
import { Controller, useForm } from 'react-hook-form'

import SelectBox from 'components/SelectBox'

import useTip from 'hooks/useTip'
import useBelieve from 'hooks/useBelieve'
import useTeam from 'hooks/useTeam'
import useMatchTip from 'hooks/useMatchTip'
import useCompetition from 'hooks/useCompetition'
import DatePicker from 'components/Inputs/DatePicker'
import CropperModal from 'components/cropperModal'
import RichTextEditor from 'components/RichTextEditor'

const CONFIRM = 1
const DRAFT = 0

function InputForm({ title, data }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    setValue,
    watch,
    getValues,
  } = useForm()
  const { state: tip, fetchTips } = useTip()
  const { state: believe, fetchBelieves } = useBelieve()
  const { state: team, fetchTeams } = useTeam()
  const { state: competitions, fetchCompetitions } = useCompetition()

  // const [showPointInput, setShowPointInput] = useState(false)
  const paymentTypeWatch = watch('free')

  React.useEffect(() => {
    if (data) {
      setValue('odd_name', data.odd_name)
      setValue('hometeamId', data.hometeamId)
      setValue('awayteamId', data.awayteamId)
      setValue('free', data.free)
      setValue('link', data.link)
      if (data.free === 'Point') {
        setValue('point', data.point)
        // setShowPointInput(true)
      }
      setValue('stake', data.stake)
      setValue('result', data.result)
      setValue('rate', data.rate)
      setValue('status', data.status)
      setValue('start_date', data.start_date)
      setValue('end_date', data.end_date)
      setValue('match_date', data.match_date)
    } else {
      setValue('free', 'Free')
      setValue('match_date', new Date())
      setValue('end_date', new Date())
      setValue('start_date', new Date())
    }
    fetchTips()
    fetchBelieves()
    fetchTeams()
    fetchCompetitions()
  }, [])

  const { updateMatchTips, storeMatchTips } = useMatchTip()

  const submitData = async (values) => {
    if (values.rate === '') values.rate = null
    if (values.stake === '') values.stake = null
    if (!data && paymentTypeWatch === 'Member') {
      values.tip_id = null
      values.believe_id = null
      values.odd_name = null
      values.result = null
      values.stake = null
      values.rate = null
      values.point = null
    }
    values.confirm = CONFIRM
    //console.log(values)
    data ? await updateMatchTips(data.id, values) : await storeMatchTips(values)
    setTimeout(() => {
      window.location.reload()
    }, 1000)
  }

  const submitDraftData = async (values) => {
    values.confirm = DRAFT
   // console.log(values)
    if (values.rate === '') values.rate = null
    if (values.stake === '') values.stake = null
    if (!data && paymentTypeWatch === 'Member') {
      values.tip_id = null
      values.believe_id = null
      values.odd_name = null
      values.result = null
      values.stake = null
      values.rate = null
      values.point = null
    }
    data ? await updateMatchTips(data.id, values) : await storeMatchTips(values)
    setTimeout(() => {
      window.location.reload()
    }, 1000)
  }

  const handleConfirm = async () => {
    await updateMatchTips(data.id, { confirm: CONFIRM, confirmUpdate: true })
    setTimeout(() => {
      window.location.reload()
    }, 1000)
  }

  const competitionOptions = competitions?.competitions?.map((e) => {
    return {
      label: e.name,
      value: e.id,
    }
  })

  const tipOptions = tip?.tips?.map((e) => {
    return {
      label: e.name,
      value: e.id,
    }
  })

  const believeOptions = believe?.believes?.map((e) => {
    return {
      label: e.name,
      value: e.id,
    }
  })
  const teamOptions = team?.teams?.map((e) => {
    return {
      label: e.name,
      value: e.id,
    }
  })
  const freeOptions = [
    {
      label: 'Free',
      value: 'free',
    },
    {
      label: 'Special Member Tip',
      value: 'member',
    },
    // {
    //   label: 'Point',
    //   value: 'Point',
    // },
  ]
  const statusOptions = [
    {
      label: '',
      value: '',
    },
    {
      label: 'Won',
      value: 'Won',
    },
    {
      label: 'Lost',
      value: 'Lost',
    },
    {
      label: 'Draw',
      value: 'Draw',
    },
  ]

  return (
    <EntryGrid title={title}>
      <Form onSubmit={handleSubmit(submitData)}>
        <div className="pt-2 pb-2">
          <h3 className="text-primary">Fee</h3>
          <Row>
            <Col md="4">
              <FormGroup>
                <label>Payment Type</label>
                <SelectBox
                  control={control}
                  name="free"
                  options={freeOptions}
                  defaultValue={data ? data.free : ''}
                  rules={{ required: true }}
                  label="Payment Type"
                  // onValueChange={(val) => {
                  //   val === 'Point'
                  //     ? setShowPointInput(true)
                  //     : setShowPointInput(false)
                  // }}
                />
              </FormGroup>
            </Col>
            {paymentTypeWatch === 'Point' && (
              <Col md="4">
                <FormGroup>
                  <label>Point</label>
                  <TextBox
                    className="form-control"
                    placeholder="Point"
                    type="text"
                    registerProps={register('point', {
                      required: 'Point is required',
                    })}
                    errors={errors.point}
                  />
                </FormGroup>
              </Col>
            )}
            {paymentTypeWatch === 'free' && (
              <Col md="8">
                <FormGroup>
                  <label>Link</label>
             
                  <TextBox
                    className="form-control"
                    placeholder="Link"
                    type="text"
                    registerProps={register('link', {
                      required: 'Link is required',
                    })}
                    errors={errors.link}
                  />
                </FormGroup>
              </Col>
            )}
          </Row>
          <h3 className="text-primary">Basic Info</h3>
          <Row>
            <Col md="4">
              <FormGroup>
                <label>Competition Name</label>
                <SelectBox
                  control={control}
                  name="competition_id"
                  label="Competition"
                  options={competitionOptions}
                  defaultValue={data ? data.competition.id : ''}
                  rules={{ required: true }}
                />
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <label>Hometeam</label>
                <SelectBox
                  control={control}
                  label="Home Team"
                  name="hometeamId"
                  options={teamOptions}
                  // defaultValue={data ? data.tip.id : ''}
                  rules={{ required: true }}
                />
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <label>Awayteam</label>
                <SelectBox
                  control={control}
                  name="awayteamId"
                  label="Away Team"
                  options={teamOptions}
                  // defaultValue={data ? data.tip.id : ''}
                  rules={{ required: true }}
                />
              </FormGroup>
            </Col>
          </Row>

          {paymentTypeWatch !== 'free' && (
            <>
              <h3 className="text-primary">Prediction</h3>
              <Row>
                <Col md="4">
                  <FormGroup>
                    <label>Tip</label>
                    <SelectBox
                      control={control}
                      name="tip_id"
                      label="Tip"
                      options={tipOptions}
                      defaultValue={data ? data?.tip?.id : ''}
                      rules={{ required: true }}
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label>Believe</label>
                    <SelectBox
                      control={control}
                      name="believe_id"
                      label="Believe"
                      options={believeOptions}
                      defaultValue={data ? data?.believe?.id : ''}
                      rules={{ required: true }}
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label>Odds</label>
                    <TextBox
                      className="form-control"
                      placeholder="Odds"
                      label="Odds"
                      type="text"
                      registerProps={register('odd_name', {
                        required: 'Odds is required',
                      })}
                      errors={errors.odd_name}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </>
          )}

          <h3 className="text-primary">Date</h3>
          <Row>
            <Col md="4">
              <FormGroup>
                <label>Match Date</label>
                <DatePicker
                  rules={{ required: true }}
                  control={{ control }}
                  value={watch('match_date')}
                  onChange={(v) => setValue('match_date', v)}
                />
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <label>Start Date</label>
                <DatePicker
                  rules={{ required: true }}
                  control={{ control }}
                  value={watch('start_date')}
                  onChange={(v) => setValue('start_date', v)}
                />
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <label>End Date</label>
                <DatePicker
                  rules={{ required: true }}
                  control={{ control }}
                  value={watch('end_date')}
                  onChange={(v) => setValue('end_date', v)}
                />
              </FormGroup>
            </Col>
          </Row>
          {paymentTypeWatch !== 'Member' && (
            <>
              <h3 className="text-primary">Post Match</h3>
              <Row>
                <Col md="3">
                  <FormGroup>
                    <label>Result</label>
                    <TextBox
                      className="form-control"
                      placeholder="Result"
                      type="text"
                      registerProps={register('result')}
                    />
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup>
                    <label>Stake</label>
                    <TextBox
                      className="form-control"
                      placeholder="Stake"
                      type="number"
                      pattern="[0-9]*"
                      registerProps={register('stake')}
                    />
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup>
                    <label>Rate</label>
                    <TextBox
                      className="form-control"
                      placeholder="Rate"
                      type="number"
                      pattern="[0-9]*"
                      registerProps={register('rate')}
                    />
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup>
                    <label>Status</label>
                    <SelectBox
                      control={control}
                      name="status"
                      options={statusOptions}
                      defaultValue={data ? data.status : ''}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </>
          )}

          <div className="mt-5 text-center">
            {!data && (
              <Button onClick={handleSubmit(submitDraftData)}>Draft</Button>
            )}
            {data && !data?.confirm && (
              <Button onClick={handleConfirm}>Confirm</Button>
            )}
            <Button>Save</Button>
            <Button
              className="ml-2"
              onClick={() => {
                reset()
              }}
            >
              Reset
            </Button>
          </div>
        </div>
      </Form>
    </EntryGrid>
  )
}

export default InputForm
