import DateRangePicker from 'components/Inputs/DateRangePicker'
import TextBox from 'components/Inputs/TextBox'
import React from 'react'
import { Row, Col } from 'reactstrap'
import { BsSearch } from 'react-icons/bs'
import { useEffect } from 'react'
import useCompetition from 'hooks/useCompetition'
import SelectBox from 'components/SelectBox'
import { useForm } from 'react-hook-form'

function Filter({ setDateRange, handleFilter, setCompetition }) {
  const { state: competitions, fetchCompetitions } = useCompetition()
  const { control } = useForm()

  useEffect(() => {
    fetchCompetitions()
  }, [])

  const competitionOptions = competitions?.competitions?.map((e) => {
    return {
      label: e.name,
      value: e.id,
    }
  })

  return (
    <>
      <Col className="d-flex">
        <Col md={4}>
          <SelectBox
            control={control}
            name="competition_id"
            label="Competition"
            options={competitionOptions}
            placeholder={'Filter by Competition'}
            onValueChange={(v) => {
              setCompetition(v)
            }}
          />
        </Col>
        <Col md={6}>
          <DateRangePicker onDateChange={(val) => setDateRange(val)} />
        </Col>
        <Col>
          <button
            onClick={handleFilter}
            className="btn btn-success btn-sm mt-1"
          >
            <BsSearch />
          </button>
        </Col>
      </Col>
    </>
  )
}

export default Filter
