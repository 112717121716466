import React, { useState } from 'react'
import EntryGrid from 'components/Inputs/EntryGrid'
import { Col, FormGroup, Row, Form } from 'reactstrap'
import Button from 'components/Button'
import TextBox from 'components/Inputs/TextBox'
import { useForm } from 'react-hook-form'
import usePointFilling from 'hooks/usePointFilling'
import queryString from 'query-string'
import { useHistory } from 'react-router'
import useAdminPoint from 'hooks/useAdminPoint'
import { NotificationManager } from 'react-notifications'
import useAdmin from 'hooks/useAdmin'

function InputForm({ data, currentPointValue, userPoints }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm()

  const { adminPointTransfer } = useAdmin()
  const [point, setPoint] = useState(0)

  const submitData = async () => {
    if (userPoints >= point) {
      if (point > 0) {
        await adminPointTransfer({ adminId: data.id, point: point })
        reset()
      }
    } else {
      NotificationManager.warning("You don't have enough point!")
    }
  }

  return (
    <EntryGrid title="Admin Point Transfer">
      <Form onSubmit={handleSubmit(submitData)}>
        <div className="pt-2 pb-2">
          <Row>
            <Col md="6">
              <FormGroup>
                <label>MMK</label>
                <TextBox
                  className="form-control"
                  placeholder="MMK"
                  type="number"
                  registerProps={register('MMK', {
                    required: 'MMK is required',
                  })}
                  errors={errors.MMK}
                  onChange={(e) => {
                    if (e.target.value >= currentPointValue)
                      setPoint(
                        Math.floor(parseInt(e.target.value) / currentPointValue)
                      )
                    else setPoint(0)
                  }}
                />
              </FormGroup>
            </Col>
            <Col
              md="6"
              className="d-flex justify-content-center align-items-center"
            >
              <div className="d-flex align-items-end">
                <h3 className="m-0">Point = {point}</h3>
              </div>
            </Col>
          </Row>
          <div className="mt-5 text-center">
            <Button>Save</Button>
            <Button
              className="ml-2"
              onClick={() => {
                reset()
              }}
            >
              Reset
            </Button>
          </div>
        </div>
      </Form>
    </EntryGrid>
  )
}

export default InputForm
