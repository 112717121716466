import React from 'react'
import { useHistory } from 'react-router'
import Button from 'components/Button'
import List from './list'
import FilterBox from './filter'
import EditDialog from '../edit/EditDialog'
import { useState } from 'react'
import ConfirmDialog from 'components/Dialogs/ConfirmDialog'
import useMatchTip from 'hooks/useMatchTip'
import queryString from 'query-string'
import { BsSearch } from 'react-icons/bs'
import DatePicker from 'components/Inputs/DatePicker'
import { Col } from 'reactstrap'

function MatchTip() {
  const router = useHistory()
  const [editData, setEditData] = React.useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [showDelete, setShowDelete] = React.useState(false)
  const [filterDate, setFilterDate] = React.useState(null)

  const headers = [
    'No',
    'competition Name',
    'hometeam Name',
    'awayteam Name',
    'tip',
    'believe',
    'odd',
    'free',
    'result',
    'Match Date',
    '',
    '',
  ]

  const { state, fetchMatchTips, deleteMatchTips } = useMatchTip()
  const getMatchTips = async () => {
    const query = queryString.parse(router.location.search)
    query.search = 'free'
    var date = new Date()
    query.start_date = new Date(date.getFullYear(), date.getMonth(), 1)
    query.end_date = new Date(date.getFullYear(), date.getMonth() + 1, 0)
    fetchMatchTips(query)
  }
  React.useEffect(() => {
    getMatchTips()
  }, [])
  const handleFilter = () => {
    const query = queryString.parse(router.location.search)
    query.search = 'free'
    query.filter_date = filterDate
    console.log(filterDate)
    fetchMatchTips(query)
  }

  return (
    <>
      <List
        headers={headers}
        data={state?.matchTips}
        title="Match Tips"
        onDelete={(data) => {
          setEditData(data)
          setShowDelete(true)
        }}
        loading={state.loading}
        onEdit={(data) => {
          setEditData(data)
          setModalOpen(true)
        }}
        filter={
          <Col className="d-flex">
            <Col md={4}>
              <DatePicker
                value={filterDate}
                onChange={(value) => setFilterDate(value)}
              />
            </Col>
            <Col>
              <button
                onClick={handleFilter}
                className="btn btn-success btn-sm mt-1"
              >
                <BsSearch />
              </button>
            </Col>
          </Col>
        }
        onSelect={(e) => {}}
        buttons={
          <>
            <Button
              onClick={() => {
                router.push('matchtip-create')
              }}
            >
              Add New
            </Button>
            <Button
              onClick={() => {
                getMatchTips()
              }}
            >
              Refresh
            </Button>
          </>
        }
      />
      <EditDialog
        modalOpen={modalOpen}
        data={editData}
        onToggle={() => {
          setModalOpen(false)
          getMatchTips()
        }}
      />
      <ConfirmDialog
        modalOpen={showDelete}
        title={`Delete Match Tip`}
        body={`Are you sure to delete ${editData?.competition_name}?`}
        onToggle={() => setShowDelete(false)}
        onConfirm={async () => {
          setShowDelete(false)
          await deleteMatchTips(editData.id)
          await getMatchTips()
        }}
      />
    </>
  )
}

export default MatchTip
