import React from 'react'
import { getAll, store, update, deleteData } from 'services/memberRecordService'

const initialState = { memberRecords: [], loading: false }

function reducer(state, action) {
  switch (action.type) {
    case 'SET_MEMBER_RECORD':
      return { ...state, memberRecords: action.payload.data }
    case 'SET_LOADING':
      return { ...state, loading: action.payload }
    default:
      throw new Error()
  }
}

const useMemberRecord = () => {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  const fetchMemberRecords = async () => {
    dispatch({ type: 'SET_LOADING', payload: true })
    let data = await getAll()
    dispatch({ type: 'SET_MEMBER_RECORD', payload: { data: data } })
    dispatch({ type: 'SET_LOADING', payload: false })
  }

  const storeMemberRecords = async (values) => {
    await store(values)
  }
  const updateMemberRecords = async (id, values) => {
    await update(id, values)
  }
  const deleteMemberRecords = async (id) => {
    await deleteData(id)
  }

  return {
    fetchMemberRecords,
    storeMemberRecords,
    updateMemberRecords,
    deleteMemberRecords,
    state: state,
  }
}

export default useMemberRecord
