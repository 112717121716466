import Home from 'pages/home'
import Login from 'pages/Login'
import NewAdmin from 'pages/admin/new'
import Admin from 'pages/admin/list'
import Tip from 'pages/tip/list'
import Believe from 'pages/believe/list'
import Odds from 'pages/odd/list'
import MatchTip from 'pages/match tip/list'
import NewMatchTip from 'pages/match tip/new'
import Member from 'pages/member/list'
import Point from 'pages/point/list'
import PointFilling from 'pages/pointFilling/list'
import SuggestTeam from 'pages/suggest/team'
import SuggestPlayer from 'pages/suggest/player'
import Competition from 'pages/competition/list'
import Team from 'pages/team/list'
import Country from 'pages/country/list'
import Gallery from 'pages/gallery/list'


import MatchTipMember from 'pages/match tip/matchTipMember'
import MatchTipPoint from 'pages/match tip/matchTipPoint'

import AdminActive from 'pages/activeAdmin/list'
import WinPercentage from 'pages/winPercentage/list'
import Info from 'pages/info/list'
import LiveTip from 'pages/liveTip/list'
import LiveTipMatch from 'pages/liveTipMatch/list'
import OnlineTipMatch from 'pages/onlineTipMatch/list'
import OnlineTip from 'pages/onlineTip/list'
import GlobalSetting from 'pages/globalSetting'
import AdminPoint from 'pages/adminPoint/list'
import AdminPointTransfers from 'pages/pointTransfer/list'
import FreeRecord from 'pages/freeRecord/list'
import MemberRecord from 'pages/memberRecord/list'


const routes = [
  {
    path: '/login',
    name: 'login',
    icon: 'fas fa-home text-primary',
    component: Login,
    layout: '/auth',
    invisible: true,
  },
  {
    path: '',
    name: 'Home',
    icon: 'fas fa-home text-cyan',
    component: Home,
    layout: '/',
  },

  {
    collapse: true,
    name: 'Admin',
    icon: '	fa fa-file-word text-green',
    state: 'adminCollapse',
    views: [
      // {
      //   path: 'menu',
      //   name: 'Menu',
      //   miniName: 'OQ',
      //   component: Menu,
      //   layout: '/',
      // },
      {
        path: 'admin-create',
        name: 'Admin - Create',
        miniName: 'OQ',
        component: NewAdmin,
        layout: '/',
      },
      {
        path: 'admin-query',
        name: 'Admin - List',
        miniName: 'OQ',
        component: Admin,
        layout: '/',
      },
    ],
  },
  {
    path: 'point-transfers',
    name: 'Point Transfers',
    icon: 'fas fa-home text-cyan',
    component: AdminPointTransfers,
    layout: '/',
  },
  {
    path: 'member',
    name: 'Member',
    icon: 'fas fa-home text-cyan',
    component: Member,
    layout: '/',
  },

  {
    collapse: true,
    name: 'Match Tip',
    icon: '	fa fa-file-word text-green',
    state: 'matchTipCollapse',
    views: [
      {
        path: 'matchtip-create',
        name: 'Match Tip - Create',
        miniName: 'OQ',
        component: NewMatchTip,
        layout: '/',
      },
      {
        path: 'matchtip-member',
        name: 'Match tip - Special Member Tip',
        miniName: 'OQ',
        component: MatchTipMember,
        layout: '/',
      },
      {
        path: 'matchtip-free',
        name: 'Match tip - Free',
        miniName: 'OQ',
        component: MatchTip,
        layout: '/',
      },
      {
        path: 'matchtip-point',
        name: 'Match tip - Point',
        miniName: 'OQ',
        component: MatchTipPoint,
        layout: '/',
      },
      {
        path: 'win-percentage',
        name: 'Match tip - Win Percentage',
        miniName: 'OQ',
        component: WinPercentage,
        layout: '/',
      },

    ],
  },
  {
    path: 'live-match-tip',
    name: 'Live Match tip',
    icon: 'fas fa-home text-cyan',
    component: LiveTipMatch,
    layout: '/',
  },
  {
    path: 'online-match-tip',
    name: 'Online Match tip',
    icon: 'fas fa-home text-cyan',
    component: OnlineTipMatch,
    layout: '/',
  },

  {
    collapse: true,
    name: 'Setting',
    icon: '	fa fa-file-word text-green',
    state: 'settingCollapse',
    views: [
      {
        path: 'tip',
        name: 'Tip',
        miniName: 'OQ',
        component: Tip,
        layout: '/',
      },
      {
        path: 'believe',
        name: 'Believe',
        miniName: 'OQ',
        component: Believe,
        layout: '/',
      },
      {
        path: 'competition',
        name: 'Competition',
        miniName: 'OQ',
        component: Competition,
        layout: '/',
      },
      {
        path: 'point-value',
        name: 'Point value',
        miniName: 'OQ',
        component: Point,
        layout: '/',
      },
      {
        path: 'team',
        name: 'Team',
        miniName: 'OQ',
        component: Team,
        layout: '/',
      },
      {
        path: 'countries',
        name: 'Country',
        miniName: 'OQ',
        component: Country,
        layout: '/',
      },
      {
        path: 'active-admin',
        name: 'Active Admin',
        miniName: 'OQ',
        component: AdminActive,
        layout: '/',
      },
      {
        path: 'info',
        name: 'Information',
        miniName: 'OQ',
        component: Info,
        layout: '/',
      },
      {
        path: 'global-setting',
        name: 'Global Setting',
        miniName: 'OQ',
        component: GlobalSetting,
        layout: '/',
      },
      {
        path: 'gallery',
        name: 'Gallery',
        miniName: 'OQ',
        component: Gallery,
        layout: '/',
      },
    ],
  },
  {
    path: 'point/filling-history',
    name: 'Point filling history',
    icon: 'fas fa-home text-cyan',
    component: PointFilling,
    layout: '/',
    invisible: true,
  },
  {
    path: 'admin-point-history',
    name: 'Point filling history',
    icon: 'fas fa-home text-cyan',
    component: AdminPoint,
    layout: '/',
    invisible: true,
  },
  {
    path: 'live-tip/:id',
    name: 'Live Tip',
    component: LiveTip,
    layout: '/',
    invisible: true,
  },
  {
    path: 'online-tip/:id',
    name: 'Online Tip',
    component: OnlineTip,
    layout: '/',
    invisible: true,
  },
  {
    collapse: true,
    name: 'Records',
    icon: '	fa fa-file-word text-green',
    state: 'recordCollapse',
    views: [
      
      {
        path: 'free-record',
        name: 'Free Record',
        miniName: 'OQ',
        component: FreeRecord,
        layout: '/',
      },
      {
        path: 'member-record',
        name: 'Member Record',
        miniName: 'OQ',
        component: MemberRecord,
        layout: '/',
      },
      
    ],
  },
  
]

const getAllRoutes = () => {
  const allRoutes = []
  routes.map((e) => {
    if (e.invisible) {
      return null
    } else if (e.collapse) {
      e.views.map((v) => allRoutes.push(v))
    } else allRoutes.push(e)
  })
  return allRoutes
}
const allRoutes = getAllRoutes()

export { routes, allRoutes }
