import { sendData, getData } from './apiService'
import { NotificationManager } from 'react-notifications'


export async function get() {
  try {
    let response = await getData('setting/1')
    return response.data
  } catch (e) {
    NotificationManager.error('Opps. Something wrong')
    return false
  }
}

export async function update(data) {
  try {
    let response = await sendData(`setting/1`, data, 'PUT')
    return response
  } catch (e) {
    NotificationManager.error('Opps. Something wrong')
    return false
  }
}

