import React from 'react'
import EntryGrid from 'components/Inputs/EntryGrid'
import { Col, FormGroup, Row, Form } from 'reactstrap'
import Button from 'components/Button'
import TextBox from 'components/Inputs/TextBox'
import SelectBox from 'components/SelectBox'
import { useForm } from 'react-hook-form'
import useMember from 'hooks/useMember'
import { useEffect } from 'react'
import useAdmin from 'hooks/useAdmin'
import { useSelector } from 'react-redux'
function InputForm({ title, data, onToggle, modalOpen }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    setValue,
  } = useForm()
  const { state, fetchAdmins } = useAdmin()
  const admin = useSelector((state) => state.admin.adminInfo)

  React.useEffect(() => {
    if (data) {
      setValue('name', data.name)

      setValue('phone', data.phone)
      setValue('admin_id', data.admin_id)
    }
    fetchAdmins()
  }, [data])

  const { storeMember, updateMember } = useMember()

  const adminOptions = state?.admins?.map((e) => {
    return {
      label: e.name,
      value: e.id,
    }
  })

  const submitData = async (values) => {
    reset()
    data ? await updateMember(data.id, values) : await storeMember(values)
  }

  return (
    <EntryGrid title={title}>
      <Form onSubmit={handleSubmit(submitData)}>
        <div className="pt-2 pb-2">
          <Row>
            <Col md="6">
              <FormGroup>
                <label>Name</label>
                <TextBox
                  className="form-control"
                  placeholder="Name"
                  type="text"
                  registerProps={register('name', {
                    required: 'Name is required',
                  })}
                  errors={errors.name}
                />
              </FormGroup>
            </Col>

            {!data && (
              <Col md="6">
                <FormGroup>
                  <label>Password</label>
                  <TextBox
                    className="form-control"
                    placeholder="Password"
                    type="password"
                    registerProps={register('password', {
                      required: 'Password is required',
                    })}
                    errors={errors.password}
                  />
                </FormGroup>
              </Col>
            )}
            <Col md="6">
              <FormGroup>
                <label>Phone</label>
                <TextBox
                  className="form-control"
                  placeholder="Phone"
                  type="phone"
                  registerProps={register('phone', {
                    required: 'Phone is required',
                  })}
                  errors={errors.phone}
                />
              </FormGroup>
            </Col>
            <Col md="6">
              {admin?.role === 'Main Admin' ? (
                <FormGroup>
                  <label>Admin</label>
                  <SelectBox
                    control={control}
                    name="admin_id"
                    label="Admin"
                    options={adminOptions}
                    defaultValue={data ? data.admin?.id : ''}
                    rules={{ required: true }}
                  />
                </FormGroup>
              ) : (
                <FormGroup>
                  <label>Admin</label>
                  <SelectBox
                    control={control}
                    name="admin_id"
                    label="Admin"
                    options={adminOptions}
                    defaultValue={admin?.id}
                    rules={{ required: true }}
                    disabled={true}
                  />
                </FormGroup>
              )}
            </Col>
          </Row>
          <div className="mt-5 text-center">
            <Button>Save</Button>
            <Button
              className="ml-2"
              onClick={() => {
                reset()
              }}
            >
              Reset
            </Button>
          </div>
        </div>
      </Form>
    </EntryGrid>
  )
}

export default InputForm
