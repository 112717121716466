import TextBox from 'components/Inputs/TextBox'
import SelectBox from 'components/SelectBox'
import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { Row, Col, Button } from 'reactstrap'
import useAdmin from 'hooks/useAdmin'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'

function FilterBox({ headers, onSearch }) {
  const { control } = useForm()
  const admin = useSelector((state) => state.admin.adminInfo)

  const customStyles = {
    control: (base) => ({
      ...base,
      height: 35,
      minHeight: 35,
    }),
  }

  const [search, setSearch] = useState('')
  const [adminId, setAdminId] = useState(null)

  const { state, fetchAdmins } = useAdmin()
  const adminOptions = state?.admins?.map((e) => {
    return {
      label: e.name,
      value: e.id,
    }
  })

  useEffect(() => {
    fetchAdmins()
  }, [])

  return (
    <>
      <Row>
        <Col md={5} xs={5}>
          <TextBox
            styles={{ height: 34 }}
            onChange={(e) => setSearch(e.target.value)}
            value={typeof search === 'object' ? '' : search}
            placeholder="Search"
          />
        </Col>
        {admin.role === 'Main Admin' && (
          <Col md={5} xs={5}>
            <SelectBox
              control={control}
              label="Filter Admin"
              name="admin_id"
              options={adminOptions}
              onValueChange={(value) => setAdminId(value)}
            />
          </Col>
        )}
        <Col md={2} xs={2}>
          <Button
            size="sm"
            color="success"
            style={{ height: 35 }}
            onClick={() => {
              if (search) {
                onSearch({ search })
                setSearch('')
              }
              if (adminId) {
                onSearch({ adminId })
                setAdminId(null)
              }
            }}
          >
            <i className="fa fa-search" />
          </Button>
        </Col>
      </Row>
    </>
  )
}

export default FilterBox
