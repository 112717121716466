import React from 'react'
import { getAll, store, update, deleteData } from 'services/liveTip'


const initialState = { liveTips: [], loading: false }

function reducer(state, action) {
  switch (action.type) {
    case 'SET_LIVE_TIP':
      return { ...state, liveTips: action.payload.data }
    case 'SET_LOADING':
      return { ...state, loading: action.payload }
    default:
      throw new Error()
  }
}

const useLiveTip = () => {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  const fetchLiveTips = async (id) => {
    dispatch({ type: 'SET_LOADING', payload: true })
    let data = await getAll(id)
    console.log(data);
    dispatch({ type: 'SET_LIVE_TIP', payload: { data: data } })
    dispatch({ type: 'SET_LOADING', payload: false })
  }

  const storeLiveTips = async (values) => {
    await store(values)
  }
  const updateLiveTips = async (id, values) => {
    await update(id, values)
  }
  const deleteLiveTips = async (id) => {
    await deleteData(id)
  }

  return {
    fetchLiveTips,
    storeLiveTips,
    updateLiveTips,
    deleteLiveTips,
    state: state,
  }
}

export default useLiveTip
