import React from 'react'
import { getEach } from 'services/memberService'
import { getAll, store, update, deleteData } from 'services/memberService'


const initialState = { members: [],member: {}, loading: false }

function reducer(state, action) {
  switch (action.type) {
    case 'SET_MEMBER':
      return { ...state, members: action.payload.data }
    case 'SET_EACH_MEMBER':
      return { ...state, member: action.payload.data }
    case 'SET_LOADING':
      return { ...state, loading: action.payload }
    default:
      throw new Error()
  }
}

const useMember = () => {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  const fetchMember = async (query) => {
    dispatch({ type: 'SET_LOADING', payload: true })
    let data = await getAll(query)
    dispatch({ type: 'SET_MEMBER', payload: { data: data } })
    dispatch({ type: 'SET_LOADING', payload: false })
  }
  const fetchEachMember = async (id) => {
    dispatch({ type: 'SET_LOADING', payload: true })
    let data = await getEach(id)
    dispatch({ type: 'SET_EACH_MEMBER', payload: { data: data } })
    dispatch({ type: 'SET_LOADING', payload: false })
  }

  const storeMember = async (values) => {
    await store(values)
  }
  const updateMember = async (id, values) => {
    await update(id, values)
  }
  const deleteMember = async (id) => {
    await deleteData(id)
  }

  return {
    fetchMember,
    storeMember,
    updateMember,
    deleteMember,
    state: state,
    fetchEachMember
  }
}

export default useMember
