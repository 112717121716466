import React from 'react'
import { getAll, store, update, deleteData } from 'services/liveTipMatch'


const initialState = { liveTipMatchs: [], loading: false }

function reducer(state, action) {
  switch (action.type) {
    case 'SET_LIVE_MATCH_TIP':
      return { ...state, liveTipMatchs: action.payload.data }
    case 'SET_LOADING':
      return { ...state, loading: action.payload }
    default:
      throw new Error()
  }
}

const useLiveTipMatch = () => {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  const fetchLiveTipMatchs = async () => {
    dispatch({ type: 'SET_LOADING', payload: true })
    let data = await getAll()
    dispatch({ type: 'SET_LIVE_MATCH_TIP', payload: { data: data } })
    dispatch({ type: 'SET_LOADING', payload: false })
  }

  const storeLiveTipMatchs = async (values) => {
    await store(values)
  }
  const updateLiveTipMatchs = async (id, values) => {
    await update(id, values)
  }
  const deleteLiveTipMatchs = async (id) => {
    await deleteData(id)
  }

  return {
    fetchLiveTipMatchs,
    storeLiveTipMatchs,
    updateLiveTipMatchs,
    deleteLiveTipMatchs,
    state: state,
  }
}

export default useLiveTipMatch
