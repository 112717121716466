import { Col } from 'reactstrap'

function Footer({ win, draw, lost, total }) {
  return (
    <>
      <Col className="d-flex justify-content-end">
        <div>
          Win :<span className="mx-2 bg-success px-3 text-white">{win}</span>
        </div>
        <div>
          Draw :
          <span
            className="mx-2 px-3 text-white"
            style={{ backgroundColor: 'gray' }}
          >
            {draw}
          </span>
        </div>
        <div>
          Lost :<span className="mx-2 bg-danger px-3 text-white">{lost}</span>
        </div>
      </Col>
      <Col className="d-flex justify-content-end mt-3">
        <div>
          Win Percentage:
          <span className="mx-2 bg-primary px-3 text-white">
            {win === 0
              ? `0%`
              : `${parseFloat(((win / total) * 100).toFixed(2))}%`}
          </span>
        </div>
      </Col>
    </>
  )
}

export default Footer
