import React from 'react'

import Button from 'components/Button'
import List from './list'

import EditDialog from '../edit/EditDialog'
import { useState } from 'react'

import usePoint from 'hooks/usePoint'

function Point() {
  const [editData, setEditData] = React.useState(false)
  const [modalOpen, setModalOpen] = useState(false)

  const headers = ['No', 'MMK for 1 Point', 'Created At', '', '']

  const { state, fetchPoint } = usePoint()

  React.useEffect(() => {
    fetchPoint()
  }, [])

  return (
    <>
      <List
        headers={headers}
        data={state.points}
        title="Point Value Setting"
        loading={state.loading}
        onEdit={(data) => {
          setEditData(data)
          setModalOpen(true)
        }}
        onSelect={(e) => {}}
        buttons={
          <>
            <Button
              onClick={() => {
                setModalOpen(true)
              }}
            >
              Add New
            </Button>
            <Button
              onClick={() => {
                fetchPoint()
              }}
            >
              Refresh
            </Button>
          </>
        }
      />
      <EditDialog
        modalOpen={modalOpen}
        data={editData}
        onToggle={() => {
          setModalOpen(false)
          fetchPoint()
        }}
      />
    </>
  )
}

export default Point
