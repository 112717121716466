import React, { useState, useRef } from 'react'
import EntryGrid from 'components/Inputs/EntryGrid'
import { Col, FormGroup, Row, Form } from 'reactstrap'
import Button from 'components/Button'
import TextBox from 'components/Inputs/TextBox'
import { useForm } from 'react-hook-form'
import NotificationManager from 'react-notifications/lib/NotificationManager'
import useCountry from 'hooks/useCountry'
import CropperModal from 'components/cropperModal'
import { dataUrlToFile } from 'utli/fileConverter'

function InputForm({ title, data }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    setValue,
  } = useForm()

  const [image, setImage] = useState(null)
  const [openCropper, setOpenCropper] = useState(false)
  const [cropImage, setCropImage] = useState(null)
  const inputFile = useRef(null)

  React.useEffect(() => {
    if (data) {
      setValue('name', data.name)
      setValue('image', data.image)
    }
  }, [data])

  const { storeCountries, updateCountries } = useCountry()
  const submitData = async (values) => {
    if (data) {
      if (cropImage) {
        const formData = new FormData()
        const imageFile = await dataUrlToFile(cropImage)
        formData.append('name', values.name)
        formData.append('image', imageFile)
        await updateCountries(data.id, formData)
      } else {
        const formData = new FormData()
        formData.append('name', values.name)
        await updateCountries(data.id, formData)
      }
      reset()
      setImage(null)
    } else {
      if (cropImage) {
        const formData = new FormData()
        const imageFile = await dataUrlToFile(cropImage)
        formData.append('name', values.name)
        formData.append('image', imageFile)

        await storeCountries(formData)
        reset()
        setImage(null)
      } else {
        NotificationManager.warning('Please upload image!')
      }
    }
  }

  return (
    <EntryGrid title={title}>
      <Form onSubmit={handleSubmit(submitData)} encType="multipart/form-data">
        <div className="pt-2 pb-2">
          <Row>
            <Col md="6">
              <FormGroup>
                <label>Name</label>
                <TextBox
                  className="form-control"
                  placeholder="Name"
                  type="text"
                  registerProps={register('name', {
                    required: 'Name is required',
                  })}
                  errors={errors.name}
                />
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <label>Image</label>
                <div className="mt-2">
                  {cropImage ? (
                    <img
                      src={cropImage}
                      style={{ width: 150, height: 150 }}
                      onClick={() => {
                        inputFile.current.click()
                      }}
                      alt=""
                    />
                  ) : (
                    <div
                      onClick={() => {
                        inputFile.current.click()
                      }}
                      style={{ width: 150, height: 150, background: '#c3b7b7' }}
                      className="d-flex justify-content-center align-items-center"
                    >
                      <h4>Upload+</h4>
                    </div>
                  )}
                </div>
                <input
                  type="file"
                  id="file"
                  ref={inputFile}
                  style={{ display: 'none' }}
                  onChange={(event) => {
                    if (event.target.files[0]) {
                      setOpenCropper(true)
                      setImage(URL.createObjectURL(event.target.files[0]))
                    }
                  }}
                  accept="image/*"
                />
              </FormGroup>
            </Col>
          </Row>
          <div className="mt-5 text-center">
            <Button>Save</Button>
            <Button
              className="ml-2"
              onClick={() => {
                reset()
              }}
            >
              Reset
            </Button>
          </div>
        </div>
      </Form>
      {openCropper && (
        <CropperModal
          isShow={openCropper}
          image={image}
          close={() => setOpenCropper(false)}
          setCroppedImage={async (image) => {
            setCropImage(image)
          }}
        />
      )}
    </EntryGrid>
  )
}

export default InputForm
