import React from 'react'
import EntryGrid from 'components/Inputs/EntryGrid'
import { Col, FormGroup, Row, Form } from 'reactstrap'
import Button from 'components/Button'
import TextBox from 'components/Inputs/TextBox'
import { useForm } from 'react-hook-form'
import { useEffect } from 'react'
import useWinPercentage from 'hooks/useWinPercentage'
function InputForm({ title, data }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    setValue,
  } = useForm()

  React.useEffect(() => {
    if (data) {
      setValue('win_percentage', data.win_percentage)
      setValue('month', data.month)
      setValue('year', data.year)
    }
  }, [data])

  const {storeWinPercentages, updateWinPercentages } = useWinPercentage()

  const submitData = async (values) => {
    reset()
     data ? await updateWinPercentages(data.id, values) : await storeWinPercentages(values)
  }

  return (
    <EntryGrid title={title}>
      <Form onSubmit={handleSubmit(submitData)}>
        <div className="pt-2 pb-2">
          <Row>
            <Col md="4">
              <FormGroup>
                <label>Win Percentage</label>
                <TextBox
                  className="form-control"
                  placeholder="Win Percentage"
                  type="number"
                  registerProps={register('win_percentage', {
                    required: 'Win Percentage is required',
                  })}
                  errors={errors.win_percentage}
                />
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <label>Month</label>
                <TextBox
                  className="form-control"
                  placeholder="Month"
                  type="number"
                  registerProps={register('month', {
                    required: 'Month is required',
                  })}
                  errors={errors.month}
                />
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <label>Year</label>
                <TextBox
                  className="form-control"
                  placeholder="Year"
                  type="number"
                  registerProps={register('year', {
                    required: 'Month is required',
                  })}
                  errors={errors.year}
                />
              </FormGroup>
            </Col>
          </Row>
          <div className="mt-5 text-center">
            <Button>Save</Button>
            <Button
              className="ml-2"
              onClick={() => {
                reset()
              }}
            >
              Reset
            </Button>
          </div>
        </div>
      </Form>
    </EntryGrid>
  )
}

export default InputForm
