import React from 'react'
import { getAll, store, update, deleteData } from 'services/countryService'

const initialState = { countries: [], loading: false }

function reducer(state, action) {
  switch (action.type) {
    case 'SET_COUNTRY':
      return { ...state, countries: action.payload.data }
    case 'SET_LOADING':
      return { ...state, loading: action.payload }
    default:
      throw new Error()
  }
}

const useCountry = () => {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  const fetchCountries = async () => {
    dispatch({ type: 'SET_LOADING', payload: true })
    let data = await getAll()
    dispatch({ type: 'SET_COUNTRY', payload: { data: data } })
    dispatch({ type: 'SET_LOADING', payload: false })
  }

  const storeCountries = async (values) => {
    await store(values)
  }
  const updateCountries = async (id, values) => {
    await update(id, values)
  }
  const deleteCountries = async (id) => {
    await deleteData(id)
  }

  return {
    fetchCountries,
    storeCountries,
    updateCountries,
    deleteCountries,
    state: state,
  }
}

export default useCountry
