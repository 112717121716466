import React from 'react'
import { useHistory } from 'react-router'
import Button from 'components/Button'
import List from './list'
import FilterBox from './filter'
import EditDialog from '../edit/EditDialog'
import { useState } from 'react'
import ConfirmDialog from 'components/Dialogs/ConfirmDialog'
import useMember from 'hooks/useMember'
import queryString from 'query-string'

function Member() {
  const router = useHistory()
  const [editData, setEditData] = React.useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [showDelete, setShowDelete] = React.useState(false)
  const headers = ['No', 'Name', 'Phone', 'Admin', 'Joined Date', 'Point']

  const { state, fetchMember, deleteMember } = useMember()

  React.useEffect(() => {
    fetchMember(queryString.parse(router.location.search))
  }, [])

  return (
    <>
      <List
        headers={headers}
        data={state.members}
        title="Members"
        onDelete={(data) => {
          setEditData(data)
          setShowDelete(true)
        }}
        loading={state.loading}
        onEdit={(data) => {
          setEditData(data)
          setModalOpen(true)
        }}
        filter={
          <FilterBox
            onSearch={(query) => {
              window.location = `member?${new URLSearchParams(
                query
              ).toString()}`
            }}
          />
        }
        // onSelect={(e) => {}}
        buttons={
          <>
            <Button
              onClick={() => {
                setEditData(null)
                setModalOpen(true)
              }}
            >
              Add New
            </Button>
            <Button
              onClick={() => {
                window.location = `member`
              }}
            >
              Refresh
            </Button>
          </>
        }
      />
      <EditDialog
        modalOpen={modalOpen}
        data={editData}
        onToggle={() => {
          setModalOpen(false)
          fetchMember()
        }}
      />
      <ConfirmDialog
        modalOpen={showDelete}
        title={`Delete Believe`}
        body={`Are you sure to delete ${editData?.name}?`}
        onToggle={() => setShowDelete(false)}
        onConfirm={() => {
          setShowDelete(false)
          deleteMember(editData.id)
          fetchMember()
        }}
      />
    </>
  )
}

export default Member
