import { sendData, getData } from './apiService'
import { NotificationManager } from 'react-notifications'


export async function getAll(query) {
  try {
    let response = await getData(
      `point/filling-history/?&${new URLSearchParams(query)}`
    )
    return response.data
  } catch (e) {
    NotificationManager.error('Opps. Something wrong')
    return false
  }
}
export async function store(data) {
  try {
    let response = await sendData('/member/point-add', data,'PUT')
    return response.data
  } catch (e) {
    NotificationManager.error('Opps. Something wrong')
    return false
  }
}
export async function remove(data) {
  try {
    let response = await sendData('/member/point-remove', data,'PUT')
    return response.data
  } catch (e) {
    NotificationManager.error('Opps. Something wrong')
    return false
  }
}
// export async function update(id,data) {
//   try {
//     let response = await sendData(`point/${id}`, data, 'PUT')
//     return response.data
//   } catch (e) {
//     NotificationManager.error('Opps. Something wrong')
//     return false
//   }
// }

// export async function deleteData(_id) {
//   try {
//     await sendData(`point/${_id}`, null, 'DELETE')
//     return true
//   } catch (e) {
//     NotificationManager.error('Opps. Something wrong')
//     return false
//   }
// }

