import React from 'react'
import * as AdminService from 'services/adminService'
import { getAllAdminPointTransfer } from 'services/adminService'
import {
  getAll,
  store,
  update,
  deleteData,
  getActiveAdmin,
  each,
  pointTransfer,

} from 'services/adminService'
import { getCache } from 'utli/cache'
//dummy


const initialState = {
  admins: [],
  activeAdmin: {},
  admin: {},
  pointTransfers: [],
  loading: false,
}


function reducer(state, action) {
  switch (action.type) {
    case 'SET_ADMIN':
      return { ...state, admins: action.payload.data }
    case 'SET_ACTIVE_ADMIN':
      return { ...state, activeAdmin: action.payload.data }
    case 'SET_EACH_ADMIN':
      return { ...state, admin: action.payload.data }
    case 'SET_LOADING':
      return { ...state, loading: action.payload }
    case 'SET_POINT_TRANSFERS':
      return { ...state, pointTransfers: action.payload.data }
    default:
      throw new Error()
  }
}

const useAdmin = () => {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  const fetchAdmins = async (query) => {
    dispatch({ type: 'SET_LOADING', payload: true })
    let data = await getAll(query)
    dispatch({ type: 'SET_ADMIN', payload: { data: data } })
    dispatch({ type: 'SET_LOADING', payload: false })
  }

  const fetchPointTransferAdmins = async (query) => {
    dispatch({ type: 'SET_LOADING', payload: true })
    let data = await getAllAdminPointTransfer(query)
    dispatch({ type: 'SET_POINT_TRANSFERS', payload: { data: data } })
    dispatch({ type: 'SET_LOADING', payload: false })
  }

  const fetchActiveAdmin = async () => {
    dispatch({ type: 'SET_LOADING', payload: true })
    let data = await getActiveAdmin()
    dispatch({ type: 'SET_ACTIVE_ADMIN', payload: { data: data } })
    dispatch({ type: 'SET_LOADING', payload: false })
  }

  const getAdmin = async (id) => {
    dispatch({ type: 'SET_LOADING', payload: true })
    let data = await each(Number(id))

    dispatch({ type: 'SET_EACH_ADMIN', payload: { data: data } })
    dispatch({ type: 'SET_LOADING', payload: false })
  }

  const login = async (values) => {
    if (await AdminService.login(values)) window.location = '/'
  }

  const checkLogin = () => {
    const admin = getCache('admin') || null
    if (admin)
      dispatch({ type: 'SET_ADMIN', payload: { data: JSON.parse(admin) } })
    else window.location = '/auth/login'
  }
  const storeAdmins = async (values) => {
    await store(values)
  }
  const updateAdmins = async (id, values) => {
    await update(id, values)
  }
  const deleteAdmins = async (id) => {
    await deleteData(id)
  }
  const adminPointTransfer = async (values) => {
    await pointTransfer(values)
  }

  return {
    fetchAdmins,
    getAdmin,
    storeAdmins,
    updateAdmins,
    deleteAdmins,
    login,
    checkLogin,
    fetchActiveAdmin,
    fetchPointTransferAdmins,
    adminPointTransfer,
    state: state,
  }
}

export default useAdmin
