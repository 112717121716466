import React, { useState } from 'react'
import EntryGrid from 'components/Inputs/EntryGrid'
import { Col, FormGroup, Row, Form } from 'reactstrap'
import Button from 'components/Button'
import { useForm } from 'react-hook-form'

import SelectBox from 'components/SelectBox'
import useTeam from 'hooks/useTeam'
import useLiveTipMatch from 'hooks/useLiveTipMatch'
import useCompetition from 'hooks/useCompetition'

function InputForm({ title, data }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    setValue,
    watch,
    getValues,
  } = useForm()

  const { state: team, fetchTeams } = useTeam()
  const { state: competitions, fetchCompetitions } = useCompetition()

  React.useEffect(() => {
    if (data) {
      setValue('hometeamId', data.hometeamId)
      setValue('awayteamId', data.awayteamId)
      setValue('competition_id', data.competition_id)
    }
    fetchTeams()
    fetchCompetitions()
  }, [])

  const { updateLiveTipMatchs, storeLiveTipMatchs } = useLiveTipMatch()
  const submitData = async (values) => {
    data
      ? await updateLiveTipMatchs(data.id, values)
      : await storeLiveTipMatchs(values)
  }
  const competitionOptions = competitions?.competitions?.map((e) => {
    return {
      label: e.name,
      value: e.id,
    }
  })

  const teamOptions = team?.teams?.map((e) => {
    return {
      label: e.name,
      value: e.id,
    }
  })

  return (
    <EntryGrid title={title}>
      <Form onSubmit={handleSubmit(submitData)}>
        <div className="pt-2 pb-2">
          <Row>
            <Col md="6">
              <FormGroup>
                <label>Competition Name</label>
                <SelectBox
                  control={control}
                  name="competition_id"
                  label="Competition"
                  options={competitionOptions}
                  defaultValue={data ? data.competition.id : ''}
                  rules={{ required: true }}
                />
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <label>Hometeam</label>
                <SelectBox
                  control={control}
                  label="Home Team"
                  name="hometeamId"
                  options={teamOptions}
                  // defaultValue={data ? data.tip.id : ''}
                  rules={{ required: true }}
                />
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <label>Awayteam</label>
                <SelectBox
                  control={control}
                  name="awayteamId"
                  label="Away Team"
                  options={teamOptions}
                  // defaultValue={data ? data.tip.id : ''}
                  rules={{ required: true }}
                />
              </FormGroup>
            </Col>
          </Row>
          <div className="mt-5 text-center">
            <Button>Save</Button>
            <Button
              className="ml-2"
              onClick={() => {
                reset()
              }}
            >
              Reset
            </Button>
          </div>
        </div>
      </Form>
    </EntryGrid>
  )
}

export default InputForm
