import React, { useState } from 'react'
import EntryGrid from 'components/Inputs/EntryGrid'
import { Col, FormGroup, Row, Form } from 'reactstrap'
import Button from 'components/Button'
import TextBox from 'components/Inputs/TextBox'
import { useForm } from 'react-hook-form'
import usePointFilling from 'hooks/usePointFilling'
import queryString from 'query-string'
import { useHistory } from 'react-router'
import useAdminPoint from 'hooks/useAdminPoint'

function InputForm({ title, currentPointValue, status, memberPoint }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm()
  const router = useHistory()
  const data = queryString.parse(router.location.search)

  const { storeAdminPoint, removeAdminPoint } = useAdminPoint()
  const [point, setPoint] = useState(0)

  const submitData = async (values) => {
    if (status === 'add' && point > 0) {
      await storeAdminPoint({ adminId: data.admin_id, point: point })
      reset()
    } else {
      console.log('remove')
      await removeAdminPoint({
        adminId: data.admin_id,
        point: values.point,
      })
      reset()
    }
  }

  return (
    <EntryGrid title={title}>
      <Form onSubmit={handleSubmit(submitData)}>
        <div className="pt-2 pb-2">
          {status === 'add' && (
            <Row>
              <Col md="6">
                <FormGroup>
                  <label>MMK</label>
                  <TextBox
                    className="form-control"
                    placeholder="MMK"
                    type="number"
                    registerProps={register('MMK', {
                      required: 'MMK is required',
                    })}
                    errors={errors.MMK}
                    onChange={(e) => {
                      if (e.target.value >= currentPointValue)
                        setPoint(
                          Math.floor(
                            parseInt(e.target.value) / currentPointValue
                          )
                        )
                      else setPoint(0)
                    }}
                  />
                </FormGroup>
              </Col>
              <Col
                md="6"
                className="d-flex justify-content-center align-items-center"
              >
                <div className="d-flex align-items-end">
                  <h3 className="m-0">Point = {point}</h3>
                </div>
              </Col>
            </Row>
          )}

          {status === 'remove' && (
            <Row>
              <Col md="6">
                <TextBox
                  className="form-control"
                  placeholder="Point"
                  type="number"
                  registerProps={register('point', {
                    required: 'Point is required',
                  })}
                  errors={errors.point}
                  min={1}
                  max={memberPoint}
                />
              </Col>
            </Row>
          )}
          <div className="mt-5 text-center">
            <Button>Save</Button>
            <Button
              className="ml-2"
              onClick={() => {
                reset()
              }}
            >
              Reset
            </Button>
          </div>
        </div>
      </Form>
    </EntryGrid>
  )
}

export default InputForm
